import Api from './Api';
import Csrf from "./Csrf";


export default {
    async createAcademicSession(newAcademicSessionForm) {
        await Csrf.getCookie();
        return Api.post('/academicSession/create', newAcademicSessionForm);
    },

    async getAllAcademicSessions(page, per_page) {
        await Csrf.getCookie();
        return Api.get('/academicSessions/' + per_page+'?page=' + page);
    },

    async getAcademicSessions(institute_id = null) {
        await Csrf.getCookie();
        return Api.get('/academicSessions/' + institute_id);
    },

    async getActiveCourseRegistrationSessions() {
        await Csrf.getCookie();
        return Api.get('/active-course-registration-sessions');
    },

    async getInstituteActiveAndClosedAcademicSessions(institute_id, limit=null) {
        await Csrf.getCookie();
        return Api.get('/academicSessions-institute-pending-and-closed/'+institute_id+'?limit='+limit);
    },

    async getInstituteActiveAndPendingSessions(institute_id) {
        await Csrf.getCookie();
        return Api.get('/academicSessions-institute-pending-and-active/'+institute_id);
    },

    async getAllInstitutAcademicSessions(institute_id,limit=null) {
        await Csrf.getCookie();
        return Api.get('/academicSessions-institute/'+institute_id+'?limit='+limit);
    },

    async getAllStudentSessions(session_id) {
        await Csrf.getCookie();
        return Api.get('/allStudentAcademicSessions/'+ session_id);
    },
    async getAllStudentCourseRegSessions(student_id) {
        await Csrf.getCookie();
        return Api.get('/allStudentCourseRegSessions/'+ student_id);
    },

    async getCurrentAcademicSession(institute_id=null) {
        await Csrf.getCookie();
        return Api.get('/academicSession/current/'+ institute_id);
    },

    async getInstituteCurrentAcademicSession(institute_id) {
        await Csrf.getCookie();
        return Api.get('/academicSession/current/'+institute_id);
    },

    async updateAcademicSession(id, updateData) {
        await Csrf.getCookie();
        return Api.patch('/academicSession/update/' + id, updateData);
    },

    async toggleRegistrationPaymentStatus(id, status) {
        await Csrf.getCookie();
        return Api.patch('/academicSession/toggle-registration-payment-status/' + id + '/' + status);
    },

    async toggleCourseRegistrationStatus(id, status) {
        await Csrf.getCookie();
        return Api.patch('/academicSession/toggle-course-registration-status/' + id + '/' + status);
    },

    async toggleTransferRequestStatus(id, status) {
        await Csrf.getCookie();
        return Api.patch('/academicSession/toggle-transfer-request-status/' + id + '/' + status);
    },

    async endCurrentAcademicSession(session_id, institute_id = null) {
        await Csrf.getCookie();
        return Api.patch('/academicSession/current/end/' + session_id + '/' + institute_id);
    },

    async startAcademicSession(id) {
        await Csrf.getCookie();
        return Api.patch('/academicSession/start/' + id);
    },

    async getAcademicSessionCount() {
        await Csrf.getCookie();
        return Api.get('/academicSessions/count');
    },

    async createSessionSetting(configData) {
        await Csrf.getCookie();
        return Api.post('/academicSession/config/create', configData);
    },

    async saveSemesterFrequency(frequencyFormData) {
        await Csrf.getCookie();
        return Api.patch('/academicSession/semesters/frequency/', frequencyFormData);
    },
    async getSessionConfig() {
        await Csrf.getCookie();
        return Api.get('/academicSession/config');
    },

    async getSemesterConfigs() {
        await Csrf.getCookie();
        return Api.get('/academicSession/semesters/config');
    },

    async getInstituteSemesterConfigs(institute_id = null) {
        await Csrf.getCookie();
        return Api.get('/institute-academicSession/semesters/config/' + institute_id);
    },




    //------------------- registration payment extension APIs----------------------------//
    async getPaymentExemptedStudents(session_id, params) {
        await Csrf.getCookie();
        return Api.get(`/academic-sessions/payments/${session_id}/extension?${params}`);
    },

    async importStudentsToExemptFromPayment(session_id, data) {
        await Csrf.getCookie();
        return Api.post(`/academic-sessions/payments/${session_id}/extension/upload`, data,
            {
                headers: { 'content-type': 'multipart/form-data' }
            }
          );
    },

    async createStudentPaymentExtension(session_id, data) {
        await Csrf.getCookie();
        return Api.post(`/academic-sessions/payments/${session_id}/extension/store`, data);
    },

    async updateExemptedStudent(id, data) {
        await Csrf.getCookie();
        return Api.patch(`/academic-sessions/payments/extension/${id}/update`, data);
    },

    async removeFromExemptedStudent(id) {
        await Csrf.getCookie();
        return Api.delete(`/academic-sessions/payments/extension/${id}/delete`);
    },

    async checkExtensionOnClosedPayment(session_id) {
        await Csrf.getCookie();
        return Api.get(`/academic-sessions/payments/${session_id}/extension/student`);
    },

    async downloadRegistrationExtensionExcelSample() {
        await Csrf.getCookie();
        return Api.get(`/academic-sessions/payments/extension/excel-sample-download`,  {responseType: 'blob'});
    },

    //----------------------------------------------------------------------------------//


    //------------------- Semester APIs----------------------------//

    async updateSemesterConfig(id, updateData) {
        await Csrf.getCookie();
        return Api.patch('/semester-setting/update/' + id, updateData);
    },


    async getAllSemesters(page, per_page) {
        await Csrf.getCookie();
        return Api.get('/academicSession/semseters/'+per_page+'?page='+page);
    },

    async getAllInstituteAcademicSemesters(institute_id) {
        await Csrf.getCookie();
        return Api.get('/institute-academicSessions/semseters/'+institute_id);
    },

    async getCurrentSemester() {
        await Csrf.getCookie();
        return Api.get('/academicSession/semseter/current');
    },

    async startNextSemester() {
        await Csrf.getCookie();
        return Api.patch('/academicSession/semester/next/start/');
    },

    async endCurrentSemester() {
        await Csrf.getCookie();
        return Api.patch('/academicSession/semester/current/end/');
    },

    // Special Session Apis
    async storeSpecialSession(data) {
        await Csrf.getCookie();
        return Api.post('/special-sessions/store', data);
    },

    async updateSpecialSession(id, data) {
        await Csrf.getCookie();
        return Api.patch(`/special-sessions/${id}/update`, data);
    },

    async updateSpecialSessionRegistrationPaymentStatus(id, status) {
        await Csrf.getCookie();
        return Api.patch(`/special-sessions/${id}/registration-payment-status/${status}/update`);
    },

    async toggleSpecialSessionCourseRegistrationStatus(id, status) {
        await Csrf.getCookie();
        return Api.patch(`/special-sessions/${id}/course-registration-status/${status}/update`);
    },

    async startSpecialSession(id) {
        await Csrf.getCookie();
        return Api.patch(`/special-sessions/${id}/start/`);
    },

    async endCurrentSpecialSession(id) {
        await Csrf.getCookie();
        return Api.patch(`/special-sessions/${id}/end/`);
    },

    async getSpecialSession(programme_id, level_id, session_id, institute_id=null) {
        await Csrf.getCookie();
        return Api.get(`/special-sessions/${session_id}/${programme_id}/${level_id}`);
    },

    async getCurrentSpecialSession(programme_id, level_id, institute_id=null) {
        await Csrf.getCookie();
        return Api.get(`/special-sessions/current-session/${programme_id}/${level_id}/${institute_id}`);
    },

    async getEstimatedPayment(session_id) {
        await Csrf.getCookie();
        return Api.get(`/academic-sessions/payments/${session_id}/estimated`);
    }

};
